const dateFormating = (datetime) => {
  return (datetime || '')
    .replace(/T.*/g, '')
}

const datetimeFormating = (datetime) => {
  return (datetime || '')
    .replace(/T/g, ' ')
    .replace(/\..*/g, '')
}

const getDynamic = (items) => {
  return items.map(item => {
    switch (item.type) {
      case 'date': {
        return new Date(item.value).toJSON()
          .replace(/T.*/, '')
      }
      case 'datetime': {
        return new Date(item.value).toJSON()
          .replace(/T/, ' ')
          .replace(/\..*/, '')
      }
      case 'select': {
        return Array.isArray(item.value) ? item.value.join(',') : item.value
      }
      case 'text':
      case 'textarea': {
        return item.value || ''
      }
      default: {
        return item.value
      }
    }
  })
}

const getTimeValue = str => {
  const time = (str + '').replace(/\+.*/, '')
  return new Date(time).getTime()
}

const monthFormating = (datetime) => {
  return (datetime || '')
    .replace(/(\d{4}-\d{2}).*/g, '$1')
}

const monthdateFormating = (datetime) => {
  return (datetime || '')
    .replace(/\d{4}-(\d{2}-\d{2}).*/g, '$1')
}

const rowToForm = (form, row, items) => {
  items.map(item => {
    const key = item.key

    if (row) {
      switch (item.type) {
        case 'date':
        case 'datetime': {
          form[key] = new Date(row[key])
          break
        }
        case 'file': {
          break
        }
        case 'select': {
          if (item.multiple) {
            form[key] = (row[key] || '').split(',')
          } else {
            form[key] = row[key]
          }
          break
        }
        default: {
          form[key] = row[key]
        }
      }
    } else if (item.type !== 'file') {
      form[key] = null
    }
  })
}

const toFormData = (form, items) => {
  const formData = new FormData()
  let valid = true

  items.map(item => {
    const key = item.key

    switch (item.type) {
      case 'date': {
        const datetime = new Date(form[key]).toJSON()
          .replace(/T.*/, '')
          .replace(/-/g, '/')

        formData.append(key, datetime)
        break
      }
      case 'datetime': {
        const datetime = new Date(form[key]).toJSON()
          .replace(/T/, ' ')
          .replace(/\..*/, '')
          .replace(/-/g, '/')

        formData.append(key, datetime)
        break
      }
      case 'file': {
        const selector = 'input#' + key
        const input = document.querySelector(selector)

        if (input && input.files[0]) {
          formData.append(key, input.files[0])
        } else {
          valid = false
        }
        break
      }
      case 'select': {
        if (form[key].join) {
          formData.append(key, form[key].join(','))
        } else {
          formData.append(key, form[key])
        }
        break
      }
      case 'text':
      case 'textarea': {
        if (form[key] === null) {
          form[key] = ''
        }
        formData.append(key, form[key])
        break
      }
      default: {
        formData.append(key, form[key])
      }
    }
  })

  return {
    formData,
    valid
  }
}

const toNumberFixed = (value, precision) => {
  return (value - 0).toFixed(precision || 2) - 0
}

const toNumberPrecs = (value, length) => {
  return (value - 0).toPrecision(length || 3) - 0
}

export default {
  dateFormating,
  datetimeFormating,
  getDynamic,
  getTimeValue,
  monthFormating,
  monthdateFormating,
  rowToForm,
  toFormData,
  toNumberFixed,
  toNumberPrecs
}
