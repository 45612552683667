<template>

  <div
    class="app-resluts-form">

    <div class="form-container">

      <el-form
        id="submission-form"
        label-position="right"
        :model="model"
        ref="submissionForm"
        size="small">

        <!--成果名称-->
        <el-form-item
          label="Reference/Outcome"
          prop="achievementName">

          <el-input
            v-model="model.achievementName"
            name="achievementName"
            :readonly="readonly"
            :rows="2"
            type="textarea"
            style="width: 100%;">
          </el-input>

        </el-form-item>

        <!--成果日期-->
        <el-form-item
          label="Publish Date"
          prop="achievementDate">

          <el-date-picker
            v-model="model.achievementDate"
            class="app-select"
            name="achievementDate"
            :readonly="readonly"
            type="date"
            value-format="yyyy/MM/dd"
            style="width: 100%;">
          </el-date-picker>

        </el-form-item>

        <!--成果简介-->
        <el-form-item
          label="Brief Introduction"
          prop="achievementBrief">

          <el-input
            v-model="model.achievementBrief"
            name="achievementBrief"
            :readonly="readonly"
            :rows="3"
            type="textarea"
            style="width: 100%;">
          </el-input>

        </el-form-item>

        <!-- 所用数据集 -->
        <el-form-item
          label="Dataset"
          prop="datasetIds">

          <el-select
            v-model="model.usedDataset"
            class="app-select"
            :disabled="noDatasets || readonly"
            filterable
            :loading="loading"
            multiple
            remote
            :remote-method="remoteMethod"
            reserve-keyword
            style="width: 100%;">

            <el-option
              v-for="(item, index) in options.datasetIds"
              :disabled="readonly"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>

          </el-select>

        </el-form-item>

        <!-- 服务类型 -->
        <el-form-item
          label="Source Type"
          prop="serviceType">

          <el-select
            v-model="model.serviceType"
            class="app-select"
            @change="changeType"
            style="width: 100%;">

            <el-option
              v-for="(item, index) in options.serviceType"
              :disabled="readonly || detail.id"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>

          </el-select>

        </el-form-item>

        <!--成果类型-->
        <el-form-item
          label="Type"
          prop="achievementType">

          <el-select
            v-model="model.achievementType"
            class="app-select"
            @change="changeAchievementType"
            style="width: 100%;">

            <el-option
              v-for="(item, index) in options.achievementType"
              :disabled="readonly"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>

          </el-select>

        </el-form-item>

        <!--成果贡献者-->
        <el-form-item
          label="Outcome Provider"
          prop="achievementContributor">

          <el-input
            v-model="model.achievementContributor"
            name="achievementContributor"
            :readonly="readonly"
            type="text"
            style="width: 100%;">
          </el-input>

        </el-form-item>

        <!--数据贡献者-->
        <el-form-item
          label="Data Provider"
          prop="dataContributor">

          <el-input
            v-model="model.dataContributor"
            name="dataContributor"
            :readonly="readonly"
            type="text"
            style="width: 100%;">
          </el-input>

        </el-form-item>

        <template v-if="show.items">

          <el-form-item
            v-for="(item, i) in items"
            :key="i"
            :label="item.label"
            :prop="item.prop">

            <el-input
              v-model="model[item.prop]"
              :name="item.prop"
              :readonly="readonly"
              type="text"
              style="width: 100%;">
            </el-input>

          </el-form-item>

        </template>

        <!-- 数据附件提交/回显 附件-->
        <el-form-item
          class="align-left"
          label="Attachment">

          <div
            v-for="(item, index) in annexes"
            :key="index"
            style="width: 100%;">

            <span
              :class="item.id ? 'file-item' : ''"
              @click="downloadFiles(item)">{{ item.name }}
            </span>

            <i
              v-if="!readonly && !item.id"
              class="el-icon-delete file-delete-icon"
              @click="deleteFile(index)">
            </i>

          </div>

        </el-form-item>

        <el-form-item style="text-align: center">

          <el-button
            v-if="readonly"
            @click="close()">Close
          </el-button>

        </el-form-item>

      </el-form>

    </div>

  </div>

</template>

<script>
import { result } from '@/configs/api'

export default {
  name: 'app-resluts-form',
  data () {
    return {
      annexes: [],
      baseURL: process.env.VUE_APP_BASE_URL || '',
      id: '',
      items: [
        {
          label: 'Author', // 第一作者/联系人
          prop: 'firstAuthor',
          type: 'text'
        },
        {
          label: 'Publications/Monographs/Patents/Projects/Major Projects', // 论文刊物/专著/专利/项目/重大项目
          prop: 'projects',
          type: 'text'
        },
        {
          label: 'Periodical/Project Category', // 刊物类别/项目类别
          prop: 'projectType',
          type: 'text'
        },
        {
          label: 'Year,Volume,Issue,Page Numbers/Patent Number', // 年、卷、期、页/专利号
          prop: 'pageNo',
          type: 'text'
        }
      ],
      loading: false,
      model: {
        accessories: [],
        achievementBrief: null,
        achievementContributor: null,
        achievementDate: null,
        achievementName: null,
        achievementType: null,
        centerServiceContent: null,
        dataContributor: null,
        datasetIds: [],
        usedDataset: [],
        firstAuthor: null,
        metadataId: null,
        otherIntro: null,
        pageNo: null,
        projects: null,
        projectType: null,
        relevanceServiceId: null,
        serviceType: null
      },
      noDatasets: false,
      options: {
        achievementType: [
          {
            label: '论文',
            value: '论文'
          },
          {
            label: '专著',
            value: '专著'
          },
          {
            label: '后处理数据',
            value: '后处理数据'
          },
          {
            label: '数据产品',
            value: '数据产品'
          },
          {
            label: '数据使用报告',
            value: '数据使用报告'
          },
          {
            label: '应用服务活动',
            value: '应用服务活动'
          },
          {
            label: '服务项目或课题',
            value: '服务项目或课题'
          },
          {
            label: '其他',
            value: '其他'
          }
        ],
        datasetIds: [],
        relevanceServiceId: [],
        serviceType: [
          {
            label: 'DOI/CSTR申请',
            value: 'doi'
          },
          {
            label: '数据汇交',
            value: '数据汇交'
          },
          {
            label: '超算申请',
            value: '数据资源'
          },
          {
            label: '数据申请',
            value: '数据申请'
          }
        ]
      },
      readonly: false,
      show: {
        items: true
      }
    }
  },
  props: {
    'apply-id': {
      default: '',
      required: false,
      type: String
    },
    'detail': {
      default () {
        return {
        }
      },
      required: false,
      type: Object
    },
    'detail-id': {
      default: '',
      required: false,
      type: String
    },
    'mode': {
      default: 'insert',
      required: false,
      type: String
    }
  },
  computed: {
  },
  watch: {
    detailId: 'fetchData'
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    cancel () {
      this.annexes = []
      Object.assign(this.$data.model, this.$options.data().model)
      if (this.detail.id) {
        this.$emit('close')
      } else {
        this.$router.push('/my/results-submission')
      }
    },
    changeAchievementType (value) {
      switch (value) {
        case '数据产品':
          this.show.items = false
          break
        default:
          this.show.items = true
          break
      }
    },
    changeType (value) {
      this.noDatasets = this.detail.id && value !== '数据资源'
    },
    close () {
      const query = this.$route.query
      if (this.detailId || this.detail.id) {
        this.$emit('close')
      } else if (query.id) {
        this.$router.push('/my/results-submission')
      } else {
        this.$emit('close')
      }
    },
    deleteFile (index) {
      this.annexes.splice(index, 1)
    },
    downloadFiles (row) {
      // if (row.path) {
      //   const url = `${this.baseURL}${row.path}`
      //   window.open(url, '_blank')
      // }
    },
    fetchData () {
      if (this.detailId) { // 元数据详情查看
        this.id = this.detailId
        this.readonly = this.mode === 'view'
        this.loadDetail()
      } else if (this.detail.id) {
        switch (this.mode) {
          default:
            this.readonly = false
            break
        }
      } else {
        this.model = Object.assign({}, this.$options.data().model)
        // 默认类型为论文
        this.model.achievementType = '论文'
        this.readonly = false
      }
    },
    // 获取详细信息
    loadDetail () {
      const params = new FormData()
      params.append('Id', this.id)

      this.axios.post(result.info, params)
        .then(_http => {
          const code = _http.data.code

          if (code === 200) {
            const data = _http.data.data
            data.achievementDate = (data.achievementDate || '')
              .replace(/T.*/, '')
              .replace(/(-)/g, '/')
            data.usedDataset = []
            this.model = Object.assign({}, data)
            // 回显数据集
            data.briefMetadataList.forEach(item => {
              this.model.usedDataset.push(item.datasetName)
            })
            // 回显文件
            const files = data.accessoriesList
            if (files && files.length) {
              data.accessoriesList.forEach(item => {
                this.annexes.push({
                  id: item.fileId,
                  file: null,
                  name: item.fileName,
                  path: item.filePath
                })
              })
            }
            setTimeout(() => {
              this.changeType(data.serviceType)
            }, 0)
          } else {
            const message = _http.data.message || _http.data.msg
            this.$message.error(message || '查询数据失败')
          }
        })
        .catch(err => {
          console.error(err)
          this.$message.error('系统问题，请联系管理员！')
        })
    },
    remoteMethod (e) {
      let list = []
      this.options.datasetIds = []
      if (e) {
        this.loading = true
        const params = {
          datasetName: e
        }

        this.axios.get(result.list, { params }).then((response) => {
          const res = response.data
          if (res.code === 200) {
            list = res.data.slice()
            list.forEach(item => {
              this.options.datasetIds.push({
                label: item.datasetName,
                value: item.datasetId
              })
            })
          } else {
            this.$message.error(res.msg || res.message || '获取数据失败！')
          }
        }).catch(err => {
          console.error(err)
          this.$message.error('系统问题，请联系管理员！')
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.options.datasetIds = []
      }
    }
  }
}
</script>

<style scoped>
.app-resluts-form {
  background-color: white;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.form-container {
  margin-left: auto;
  margin-right: auto;
  width: 740px;
}
.app-select {
  width: 100%;
}
input#annex {
  display: none;
  position: absolute;
}
.align-left {
  text-align: left;
}
.file-delete-icon {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
.file-item {
  /*color: #409EFF;*/
  /*cursor: pointer;*/
}
#submission-form >>> .el-form-item {
  display: flex;
  flex-direction: column;
}
#submission-form >>> .el-form-item__label {
  flex-shrink: 0;
  text-align: left;
  /*width: 140px;*/
}
#submission-form >>> .el-form-item__content {
  flex-grow: 1;
}
</style>
