<template>

  <!-- FTP文件展示 -->
  <div class="app-prod-file">

    <div class="section-gap">

      <i class="section-gap-icon">
      </i>

      <p class="section-gap-content">Data download
      </p>

      <!-- 暂时注释掉目录结构 -->
      <!--<ul
        class="file-breadcrumb">

        <li
          v-for="(item, index) in breadcrumb"
          class="file-breadcrumb-item"
          :key="index">

          <span
            v-show="item.prefix"
            class="file-breadcrumb-gap">>
          </span>

          <span
            class="file-breadcrumb-content"
            @click="loadData(item.url)">{{ item.label }}
          </span>

        </li>

      </ul>-->

    </div>

    <el-table
      :data="list"
      ref="file-table"
      size="small"
      @selection-change="handleSelectChange">

      <el-table-column
        label="File name"
        prop="name"
        show-overflow-tooltip>

        <template slot-scope="props">

          <span
            v-if="props.row.directory"
            class="prod-file-download prod-file-name"
            @click="loadData(props.row.url)">

          <img
            alt="ICON_FOLDER"
            class="prod-file-icon"
            src="/img/data/files/folder.png">

          <span>{{ props.row.name }}
          </span>

        </span>

          <span
            v-else
            class="prod-file-download prod-file-name"
            @click="downloadFile(props.row)">

          <img
            alt="ICON_FILE"
            class="prod-file-icon"
            src="/img/data/files/file.png">

          <span>{{ props.row.name }}
          </span>

        </span>

        </template>

      </el-table-column>

      <el-table-column
        label="File size(MB)"
        prop="size"
        width="150">
      </el-table-column>

      <el-table-column
        label="File type"
        prop="type"
        width="80">
      </el-table-column>

      <el-table-column
        label="Last modified time"
        prop="datetime"
        width="160">
      </el-table-column>

    </el-table>

  </div>

</template>

<script>
import * as api from '@/configs/api'
import util from '@/util/index'

export default {
  name: 'app-files',
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String,
      required: false,
      default: ''
    },
    share: {
      type: Boolean,
      required: false,
      default: true
    },
    url: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      breadcrumb: [
        {
          label: 'root directory',
          prefix: false,
          url: 'ltgkserver/product1'
        }
      ],
      check: {
        down: [],
        list: []
      },
      list: [],
      prevUrl: null
    }
  },
  watch: {
    $route: 'loadData',
    url: 'loadData'
  },
  mounted () {
    setTimeout(() => {
      this.loadData()
    }, 2 * 1000)
  },
  methods: {
    downloadFile (e) {
      if (!this.share) {
        this.$message.warning('Add this dataset in your Data Basket and then apply this data in Data Basket!') // 请加入到数据篮后申请下载！
        return null
      }
      // const url = e.url.split('/')
      // url.splice(0, 3)
      // // url.push(e.name)
      // const pathName = url.join('/')
      const url = e.url.split('/')
      url.splice(0, 3)
      const pathName = url.join('/')
      // const location = window.location
      const requestURL = [
        this.baseURL,
        api.subject.download,
        '?filePath=',
        pathName,
        // '&entryAddr=',
        // location,
        '&datasetId=',
        this.id,
        '&moduleName=',
        this.mode,
        '&dataSize=',
        e.size
      ].join('')
      window.open(requestURL, '_blank')
    },
    downloadMore () {
      // let message = ''
      this.check.list.forEach(item => {
        if (item.type !== 'file') {
          this.$refs['file-table'].toggleRowSelection(item, false)
          // message = '此项为文件夹，不可下载！'
        } else {
          const url = item.url.split('/')
          url.splice(0, 3)
          url.push(item.name)
          const pathName = url.join('/')
          this.check.down.push(pathName)
        }
      })
      // if (message) {
      //   this.$message.warning(message)
      // }
      if (!this.check.down.length) {
        this.$message.warning('Please select the file to download！')
        return null
      }
      const requestURL = [
        this.baseURL,
        api.subject.downloadMore,
        '?filePaths=',
        this.check.down,
        '&entryAddr=',
        location,
        '&datasetId=',
        this.id,
        '&moduleName=',
        this.mode
      ].join('')

      window.open(requestURL, '_blank')
    },
    handleSelectChange (e) {
      this.check.list = e.slice()
    },
    loadData (current) {
      const params = new FormData()
      const url = (current || window.url).replace(/`/g, '/')
      if (url === window.url) {
        this.prevUrl = null
      } else {
        const urls = url.split('/')
        this.prevUrl = urls.slice(0, urls.length - 1).join('/')
        // this.prevUrl = url.replace(/(\/[A-Za-z0-9-.]*)$/, '')
      }

      params.append('url', url)

      this.updateBreadcrumb(url)
      if (!url) {
        return null
      }

      this.axios.post(api.subject.files, params)
        .then((response) => {
          const baseURL = decodeURI(url)
          const res = response.data

          if (res.code === 200 && res.data) {
            this.list = res.data.map(item => {
              item.datetime = util.datetimeFormating(item.timestamp)
              if (item.directory) {
                item.size = '-'
                item.type = 'folder'
                item.url = `${baseURL}/${item.name}`
              } else {
                item.size = (item.size / 1024 / 1024).toFixed(4)
                item.type = 'file'
                item.url = `ftp://${url}/${item.name}`
              }
              return item
            })

            if (this.prevUrl) {
              this.list.unshift({
                directory: true,
                name: '../',
                size: '-',
                type: 'Go Back',
                url: `${this.prevUrl}`
              })
            }
          }
        })
    },
    updateBreadcrumb (url) {
      const list = url.split('/')
      let base = window.url

      this.breadcrumb = [
        {
          label: 'root directory',
          prefix: false,
          url: window.url
        }
      ]

      list.forEach((item, index) => {
        if (index > 1) {
          base = `${base}/${item}`
          this.breadcrumb.push({
            label: item,
            prefix: true,
            url: base
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.app-prod-file {
  background-color: white;
  width: 100%;
}

.prod-file-title {
  border-bottom-color: #DCDFE6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.section-gap {
  margin-bottom: 15px;
  position: relative;
  text-align: left;
}
.section-gap-icon {
  border-bottom: none;
  border-left-color: rgba(16, 90, 190, 1);
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: rgba(16, 90, 190, 1);
  border-right-style: solid;
  border-right-width: 7px;
  border-top: none;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  top: 4px;
  width: 12px;
}
.section-gap-content {
  color: rgba(16, 90, 190, 1);
  display: inline;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.file-breadcrumb {
  display: inline-block;
  margin-left: 15px;
  padding-left: 0px;
}
.file-breadcrumb-item {
  display: inline;
  font-size: 12px;
  list-style: none;
}
.file-breadcrumb-gap {
  color: lightgray;
}
.file-breadcrumb-content {
  color: #105ABE;
  cursor: pointer;
}
.file-breadcrumb-item:last-child .file-breadcrumb-content {
  color: darkgray;
  cursor: text;
}

.prod-file-list {
  padding-left: 20px;
}

.list-header {
  line-height: 40px;
  list-style: none;
  padding-left: 0px;
}
.list-header > * {
  font-size: 14px;
  /* font-weight: 600; */
}

.record {
  font-size: 12px;
  line-height: 32px;
  list-style: none;
  padding-left: 0px;
}

.prod-file-download {
  color: dodgerblue;
  cursor: pointer;
  text-decoration: none;
}

.prod-file-name {
  display: inline-block;
  text-align: left;
  width: 480px;
}
.prod-file-size {
  display: inline-block;
  width: 100px;
}
.prod-file-type {
  display: inline-block;
  width: 100px;
}
.prod-file-datetime {
  display: inline-block;
  width: 200px;
}
.prod-file-icon {
  height: 18px;
  transform: translateY(4px);
  width: 18px;
}
</style>
