// import IMG_POLY from '../../static/img/homepage/polymerization.png'

const getBase = (name) => {
  const dark = {
    urlTemplate: 'http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}',
    subdomains: ['a', 'b', 'c', 'd']
  }
  const google = {
    urlTemplate: 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
    subdomains: ['a', 'b', 'c', 'd']
  }
  const image = {
    urlTemplate: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    spatialReference: {
      projection: 'EPSG:3857'
    },
    subdomains: ['a', 'b', 'c'],
    zIndex: 1
  }
  // const vector = {
  //   'urlTemplate': 'http://cache1.arcgisonline.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}',
  //   'subdomains': ['a', 'b', 'c', 'd']
  // }
  const vector = {
    // urlTemplate: 'http://cache1.arcgisonline.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}',
    urlTemplate: 'https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=9397cc4e8a60a916752e710e72728bf3',
    spatialReference: {
      projection: 'EPSG:3857'
    },
    subdomains: ['1', '2', '3', '4', '5', '6'],
    zIndex: 1
  }
  const base = {
    dark,
    google,
    image,
    vector
  }

  if (name) {
    return base[name]
  }

  return base
}

const getInfoWindow = (titleList, lon, lat) => {
  if (typeof titleList === 'string') {
    titleList = [titleList]
  }
  const infoDom = titleList.map(title => {
    const tag = title
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
    const url = `http://portal.qdio.ac.cn/dataset/${tag}`
    return `<div class="info-row">
      <a class="tag-alink" href="${url}" target="_blank">${title}</a>
    </div>`
  })
  const options = {
    autoPan: true,
    single: true,
    custom: true,
    dx: 0,
    dy: -35,
    content: ''
  }

  if (lat && lon) {
    infoDom.push(
      `<div class="info-row">
        <p class="info-label">经度</p>
        <p class="info-value">${lon}</p>
        <p class="info-label">纬度</p>
        <p class="info-value">${lat}</p>
      </div>`
    )
  }
  options.content = `<div class="app-info-window">${infoDom.join('')}</div>`

  return options
}

const getLine = (id) => {
  const color = id.replace(/-/g, '')
    .replace(/^(.{6}).*/, '$1')

  return {
    id: `${id}`,
    symbol: {
      lineColor: `#${color}`,
      lineWidth: 1,
      lineJoin: 'round',
      lineCap: 'round',
      lineDasharray: null,
      lineOpacity: 0.6
    }
  }
}

const getMarker = (id, type) => {
  const colors = [
    'black', 'black', 'black', 'black'
  ]
  const fills = [
    'rgb(135, 196, 240)', 'yellow',
    'rgb(135, 0, 240)', 'rgb(135, 196, 0)'
  ]
  const types = [
    'ellipse', 'ellipse', 'ellipse', 'ellipse'
    // 'ellipse', 'diamond', 'triangle', 'square'
  ]
  return {
    id: id,
    symbol: {
      markerType: types[type],
      markerFill: fills[type],
      markerFillOpacity: 1,
      markerLineColor: colors[type],
      markerLineWidth: 1,
      markerLineOpacity: 1,
      markerLineDasharray: [],
      markerWidth: { stops: [[7, 10], [11, 20]] },
      markerHeight: { stops: [[7, 10], [11, 20]] },
      markerDx: 0,
      markerDy: 0,
      markerOpacity: 0.6
    }
  }
}

export default {
  getBase,
  getInfoWindow,
  getLine,
  getMarker
}
