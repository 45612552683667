<template>

  <div class="app-metadata-detail">

    <section class="metadata-content">

      <el-button
        v-if="chinese.id"
        class="to-chinese"
        plain
        size="small"
        type="primary"
        @click="checkChinese">中文
      </el-button>

      <!-- 标题 -->

      <p id="metadata-title"><span @click="checkChinese">{{ detail.name }}</span>
      </p>

      <!-- 数据贡献者 -->
      <!--Provided by:&emsp;{{ detail.contributor }}-->
      <p id="metadata-contributor">Provided by:&emsp;{{ detail.contributor }}</p>
      <!-- <p id="metadata-contributor">

        <span style="margin-right: 15px;">First name: {{detail.firstName}}</span>

        <span>Last name: {{detail.lastName}}</span>

      </p> -->

      <!-- 数据信息条 -->
      <ul id="metadata-detail-bar">

        <li v-for="(item, index) in bar"
          :key="index">

          <i-icon
            :class="['icon']"
            :type="item.icon"
            :style="{ 'background-color': item.color }">
          </i-icon>

          <p>{{ item.value }}&nbsp;{{ item.suffix }}
          </p>

          <p>{{ item.label }}
          </p>

        </li>

      </ul>

      <!-- 数据摘要 -->
      <div id="metadata-summary">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Abstract
          </span>

        </div>

        <div v-html="detail.summary"
             class="metadata-summary-content">
        </div>

      </div>

      <!-- 数据信息卡 -->
      <ul id="metadata-detail-card">

        <li v-for="(item, index) in card"
          :key="index">

          <span class="label">{{ item.label }}
          </span>

          <a v-if="item.link"
            :href="item.value"
            target="_blank">

            <span>{{ item.value }}
            </span>

            <i class="el-icon-link">
            </i>

          </a>

          <span v-else
            class="value">{{ item.value }}
          </span>

        </li>

        <li>

          <span class="label">License
          </span>

          <span class="value">CC BY 4.0
          </span>

        </li>

      </ul>

      <!-- 基本信息 -->
      <div id="metadata-detail-base">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Metadata
          </span>

        </div>

        <div class="metadata-detail-base-info">

          <ul>

            <li
              v-for="(item, index) in base"
              :key="index"
              :style="`width: ${item.width};`">

              <span class="label">{{ item.label }}:
              </span>

              <span class="value">{{ item.value }}
              </span>

            </li>

          </ul>

          <!-- 轮播图 -->
          <div class="metadata-carousel">

            <el-carousel
              trigger="click"
              indicator-position="none"
              height="143px">

              <el-carousel-item
                v-for="(item, index) in carousel"
                :key="index">

                <el-image
                  style="width: 231px; height: 143px"
                  :src="item.path"
                  :preview-src-list="carousel.map(item => item.path)">
                </el-image>

              </el-carousel-item>

            </el-carousel>

            <!--<el-carousel
              indicator-position="none"
              trigger="click"
              height="143px">

              <el-carousel-item
                v-for="(item, index) in carousel"
                :key="index">

                <img
                  alt="CAROUSEL_ITEM"
                  height="143"
                  :src="item.path"
                  width="231"
                  style="cursor: pointer;"
                  @click="showMore">

              </el-carousel-item>

            </el-carousel>-->

            <!-- 点击查看大图 -->
            <div class="click-more">Please click to enlarge</div>

          </div>

        </div>

      </div>

      <!-- 数据联系信息 -->
      <div id="metadata-detail-contact">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Contact
          </span>

        </div>

        <div class="metadata-detail-contact-info">

          <ul>

            <li
              v-for="(item, index) in contact"
              :key="index">

              <span class="label">{{ item.label }}:
              </span>

              <span class="value">{{ item.value }}
              </span>

            </li>

          </ul>

          <!-- 地图 -->
          <div
            class="metadata-thumbnail"
            id="thumbnail">
          </div>

        </div>

      </div>

      <!-- 数据使用申明 -->
      <div id="metadata-usage-statement">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Data usage statement
          </span>

        </div>

        <ul>

          <li v-for="(item, index) in statement"
            :key="index">

            <div class="subtitle">{{ item.label }}:
            </div>

            <p v-for="(text, tid) in item.value"
              :key="tid">{{ text }}
            </p>

          </li>

        </ul>

      </div>

      <!-- Tabs -->
      <div id="metadata-tabs">

        <ul class="tabs-header">

          <li v-for="(item, index) in tabs.list"
            :class="activeTab(item.key)"
            :key="index"
            @click="toggleTab(item.key)">{{ item.label }}
          </li>

        </ul>

        <!-- 数据详细描述 -->
        <div v-show="tabs.current === 'description'"
          id="metadata-description">

          <ul>

            <li v-for="(item, index) in description"
              :key="index">

              <div class="subtitle">{{ item.label }}
              </div>

              <p>{{ item.value }}
              </p>

            </li>

          </ul>

        </div>

        <!-- 扩展信息 -->
        <div v-show="tabs.current === 'extra'"
          id="metadata-usage-statement"
          class="metadata-usage-statement">

          <ul>

            <li v-for="(item, index) in extra"
              :key="index">

              <div class="subtitle">{{ item.label }}:
              </div>

              <p>{{ item.value }}
              </p>

            </li>

          </ul>

        </div>

        <!-- 相关成果 -->
        <div v-show="tabs.current === 'results'"
          id="related-results">

          <el-table
            :data="results.list"
            size="mini">

            <el-table-column
              type="index"
              :width="40">
            </el-table-column>

            <el-table-column v-for="(item, index) in results.columns"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              :show-overflow-tooltip="item.tooltip"
              :width="item.width">
            </el-table-column>

            <el-table-column
              label="control"
              :width="100">

              <template slot-scope="props">

                <el-button
                  plain
                  size="mini"
                  @click="toggleResultDrawer(props.row)">preview
                </el-button>

              </template>

            </el-table-column>

          </el-table>

          <el-pagination
            class="result-pager"
            small
            layout="total, prev, pager, next"
            :total="pager.total"
            @current-change="handleCurrentChange">
          </el-pagination>

        </div>

        <!-- 相关文献 -->
        <div v-show="tabs.current === 'related-literature'"
          id="metadata-related-literature">

          <template v-if="literature.length">

            <p v-for="(item, index) in literature"
              :key="index">{{ item }}
            </p>

          </template>

          <p v-else
            class="empty">No Data
          </p>

        </div>

      </div>

      <!-- 数据下载 -->
      <template v-if="toggleFtp()">

        <app-files
          :id="ftp.id"
          :mode="ftp.mode"
          :share="toggleFtpShare()"
          :url="ftp.url">
        </app-files>

      </template>

      <!-- 平台申明 -->
      <div id="platform-statement">

        <div class="metadata-section-header">

          <span class="metadata-section-icon">&nbsp;
          </span>

          <span class="metadata-subtitle">Platform statement
          </span>

        </div>

        <ul>

          <li>The platform owns the copyright and other intellectual property rights of all the data products or services published by it or contributed by data contributors and the relevant information published on the platform, which are protected by law.
          </li>

          <li>Without the written permission of the platform, no unit or individual is allowed to copy, modify, transcribe, disseminate and sell any part of the above data products, services, information and materials in any way or for any reason.
          </li>

          <li>Any infringement of the copyright and other intellectual property rights of the platform, the platform will be investigated for its legal responsibility in accordance with the law, hereby solemnly declare!
          </li>

        </ul>

      </div>

    </section>

    <el-dialog
      :visible.sync="dialog.visible"
      width="500px">

      <!-- 轮播图 -->
      <div class="metadata-carousel-dialog">

        <el-carousel
          indicator-position="none"
          trigger="click"
          height="500.5px">

          <el-carousel-item
            v-for="(item, index) in carousel"
            :key="index">

            <img
              alt="CAROUSEL_ITEM"
              height="500.5"
              :src="item.path"
              width="808.5">

          </el-carousel-item>

        </el-carousel>

      </div>

    </el-dialog>

    <!-- 页面导航 -->
    <ul v-show="nav.visible"
      class="page-nav">

      <li v-for="(item, index) in nav.options"
        :class="['page-nav-item', activeNav(index)]"
        :key="index"
         @click="toggleNav(index)">

        <span>{{ item.label }}
        </span>

      </li>

    </ul>

    <!-- 相关成果 -->
    <el-drawer
      :size="drawer.width"
      :visible.sync="drawer.show"
      :withHeader="false"
      @close="resetPreview">

      <app-result-form
        :detail-id="drawer.id"
        mode="view"
        @close="resetPreview">
      </app-result-form>

    </el-drawer>

  </div>

</template>

<script>
import 'maptalks/dist/maptalks.css'
import * as maptalks from 'maptalks'

import { metadata, other, result } from '@/configs/api'
import mapConfigs from '@/configs/map'

import appFiles from './files'
import appResultForm from './result-detail'

const debounce = (callback) => {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      callback()
    }, 100)
  }
}

export default {
  name: 'app-metadata-detail',
  components: {
    'app-files': appFiles,
    'app-result-form': appResultForm
  },
  data () {
    return {
      attachment: [],
      base: [
        { // 时间跨度
          time: true,
          label: 'Time Range',
          prop: 'timeRange',
          value: '1980.01-2020.12',
          width: '50%'
        },
        { // 水平分辨率
          label: 'Horizontal Resolution',
          prop: 'horizontalResolution',
          value: '0.25°×0.25°',
          width: '50%'
        },
        { // 空间区域
          label: 'Region',
          prop: 'spaceArea',
          value: 'latRange',
          width: '50%'
        },
        { // 垂直分辨率
          label: 'Vertical Resolution',
          prop: 'verticalResolution',
          value: '-',
          width: '50%'
        },
        { // 经度范围
          lon: true,
          label: 'Longitude',
          prop: 'lonRange',
          value: '-',
          width: '50%'
        },
        { // 时间分辨率
          label: 'Temporal Resolution',
          prop: 'timeResolution',
          value: 'monthly average',
          width: '50%'
        },
        { // 纬度范围
          lat: true,
          label: 'Latitude',
          prop: 'latRange',
          value: '-',
          width: '50%'
        },
        { // 存储格式
          label: 'Format',
          prop: 'storageFormat',
          value: 'nc',
          width: '50%'
        },
        { // 要素信息
          label: 'Parameters',
          prop: 'vars',
          value: 'Latent heat flux, 10 m wind speed, 2 m air temperature, 2 m specific humidity, sea surface temperature',
          width: '50%'
        },
        { // 关键词
          label: 'Keywords',
          prop: 'keywords',
          value: 'coastal waters of china Air-sea flux',
          width: '50%'
        },
        { // 资助项目
          label: 'Supportive Project',
          prop: 'supportProject',
          value: 'The National Key Research and Development Program for Global Change and Countermeasures',
          width: '100%'
        }
      ],
      buffer: {
      },
      bar: [
        {
          color: 'rgba(255, 163, 15, 1)',
          datetime: true,
          // icon: 'icon-time',
          icon: 'ios-time',
          label: 'First Release Time',
          prop: 'publishTime',
          suffix: '',
          value: '2020-12-17 09:16'
        },
        {
          color: 'rgba(218, 139, 101, 1)',
          // icon: 'icon-fsb_datasize',
          icon: 'logo-buffer',
          label: 'Data Size',
          prop: 'dataCapacity',
          suffix: '',
          value: 282.62
        },
        {
          color: 'rgba(201, 209, 146, 1)',
          // icon: 'icon-view',
          icon: 'ios-exit',
          label: 'Pageviews',
          prop: 'visitCount',
          suffix: 'times',
          value: 1872
        },
        {
          color: 'rgba(95, 112, 177, 1)',
          // icon: 'icon-download',
          icon: 'md-arrow-round-down',
          label: 'Download Times',
          prop: 'downloadCount',
          suffix: 'times',
          value: 1872
        }
      ],
      card: [
        // {
        //   label: 'Chinese Name',
        //   prop: 'cnName',
        //   value: '100m×100m空间分辨率的黑河流域中上游气候平均逐月降水量'
        // },
        {
          label: 'Name',
          prop: 'enName',
          value: 'China sea and air flux grid data products'
        },
        {
          label: 'DOI',
          link: true,
          prop: 'doi',
          value: 'http://dx.doi.org/10.12072/ncdc.Westdc.db0020.2020'
        },
        {
          label: 'CSTR',
          link: true,
          prop: 'cstr',
          value: 'CSTR:11738.11.ncdc.Westdc.2020.718'
        },
        {
          label: 'Dataset Coding',
          prop: 'datasetCode',
          value: '-'
        },
        {
          label: 'Open Access',
          prop: 'shareMode',
          value: 'open'
        },
        {
          label: 'Classification',
          prop: 'dataClassify',
          value: 'basic geographic'
        }
      ],
      carousel: [
        {
          path: '/img/test.jpg'
        },
        {
          path: '/img/test.jpg'
        }
      ],
      chinese: {
        id: null
      },
      contact: [
        {
          label: 'Provided by',
          prop: 'dataProducer',
          value: 'Zhang Rongwang'
        },
        {
          label: 'Organization',
          prop: 'producerOrgan',
          value: 'South China Sea Institute of Oceanology Chinese Academy of Sciences'
        },
        {
          label: 'Email',
          prop: 'producerContactWay',
          value: 'rwzhang@qdio.ac.cn'
        },
        {
          label: 'Data Center Contact',
          prop: 'centerLinkman',
          value: 'Zhang Bin'
        },
        {
          label: 'Contact Tel',
          prop: 'centerContactWay',
          value: 'zhangbin@qdio.ac.cn'
        }
      ],
      description: [
        {
          label: 'Data source description',
          prop: 'datasourceDescribe',
          value: '-'
        },
        {
          label: 'Data processing method',
          prop: 'dataProcessMethod',
          value: '-'
        },
        {
          label: 'Data quality description',
          prop: 'dataQualityDescribe',
          value: '-'
        }
      ],
      detail: {
        contributor: '',
        name: 'China sea and air flux grid data products',
        summary: 'The data set is developed by researcher Wang Xin and assistant researcher Zhang Rongwang of South China Sea Institute of Oceanography, a co-constructed unit of the Marine Science Data Center, Chinese Academy of Sciences. It is mainly based on satellite remote sensing and reanalysis data, and takes the observation data of China offshore buoy, offshore platform and flux tower as training data. It uses convolutional neural network deep learning and improved COARE 3.5 algorithm and other technologies to effectively overcome the prominent problems such as underestimation of latent heat flux under the condition of medium atmospheric specific humidity and error of latent heat flux caused by cold skin effect of sea surface. Compared with the international mainstream air sea flux products, this product has significant regional advantages and performance index advantages in China offshore.'
      },
      dialog: {
        visible: false
      },
      drawer: {
        id: '',
        show: false,
        width: 790
      },
      ftp: {
        id: '',
        mode: '专题数据产品',
        url: ''
      },
      ftpDownloadUrl: '',
      extra: [
        {
          label: 'Investigation Paltform',
          prop: 'surveyPaltform',
          value: 'Test Platform'
        },
        {
          label: 'Investigation Organization',
          prop: 'surveyInstitution',
          value: 'Test Institution'
        },
        {
          label: 'Instruments and Apparatuses',
          prop: 'instruments',
          value: 'Test Instruments'
        },
        {
          label: 'Manufacturer',
          prop: 'manufacturer',
          value: 'Test Manufacturer'
        },
        // {
        //   label: 'Subject Classification',
        //   prop: 'subjectClassify',
        //   value: 'Test Class'
        // },
        {
          label: 'Voyage Name',
          prop: 'voyage',
          value: '航次名称'
        },
        {
          label: 'Buoy Number',
          prop: 'buoyNo',
          value: '浮标号'
        },
        {
          label: 'Buoy Description',
          prop: 'buoyType',
          value: '浮标说明'
        }
      ],
      geometry: null,
      id: '',
      layer: null,
      literature: [
      ],
      map: null,
      nav: {
        current: -1,
        disabled: false,
        options: [
          {
            anchor: 'metadata-title',
            label: '',
            top: 30
          },
          {
            anchor: 'metadata-summary', // 数据摘要
            label: 'Abstract',
            top: 207
          },
          {
            anchor: 'metadata-detail-base', // 基本信息
            label: 'Metadata',
            top: 679
          },
          {
            anchor: 'metadata-detail-contact', // 数据联系信息
            label: 'Contact',
            top: 902
          },
          {
            anchor: 'metadata-usage-statement', // 数据使用声明
            label: 'Data usage statement',
            top: 1061
          },
          {
            anchor: 'metadata-tabs',
            diff: 0,
            label: 'Detailed description of data', // 数据详细描述
            tab: 'description',
            top: 1344
          },
          {
            anchor: 'metadata-tabs',
            diff: 1,
            label: 'Extended information', // 扩展信息
            tab: 'extra',
            top: 1344
          },
          {
            anchor: 'metadata-tabs', // 相关成果
            diff: 2,
            label: 'Reference',
            tab: 'results',
            top: 1344
          },
          {
            anchor: 'metadata-tabs', // 相关数据
            diff: 3,
            label: 'Related data',
            tab: 'related-literature',
            top: 1344
          },
          {
            anchor: 'dataset-download',
            label: 'Data download', // 数据
            top: 1598
          },
          {
            anchor: 'platform-statement', // 平台声明
            label: 'Platform statement',
            top: 2000
          }
        ],
        visible: false
      },
      pager: {
        current: 1,
        size: 5,
        total: 0
      },
      query: {},
      shareMode: '',
      statement: [
        {
          label: 'Statement',
          prop: 'dataReferenceDeclare',
          value: [
            'To respect intellectual property rights, the protection of the rights of data the authors and data service provider, please users of the data based on the data generated by the research results (including the project assessment and acceptance report, as well as academic thesis or dissertation, etc.) of the labeled data sources, and according to the literature references [way] mark should be cited references,At the same time, the publicly available results will be submitted to "Marine Science Data Center Portal Platform - Marine Big Data Center, Institute of Oceanology, Chinese Academy of Sciences".'
          ]
        },
        {
          label: 'Citation',
          prop: 'referenceMethod',
          value: [
            '* The Platform has copyright and other intellectual property rights over all contents of data products or services published by it or data contributors and related information published on the Platform, which are protected by law. * Without the written permission of the Platform, no unit or individual shall copy, modify, transcribe, spread or sell any part of the above-mentioned data products, services, information and materials in any way or for any reason. * Any infringement of intellectual property rights such as copyright of the platform, the platform will be investigated for legal responsibility according to law, hereby solemnly declare!'
          ]
        },
        {
          label: 'Acknowledgements',
          prop: 'thankWay',
          value: [
            'The portal of Marine Science Data Center, Marine Big Data Center, Institute of Oceanology, the Chinese Academy of Sciences (CAS), China (http:// msdc.qdio.ac.cn)。'
          ]
        }
      ],
      results: {
        columns: [
          {
            label: 'Reference/Outcome', // 成功名称
            prop: 'achievementName',
            tooltip: true
          },
          {
            label: 'Type',
            prop: 'achievementType',
            width: 110
          },
          {
            label: 'Code', // 编码
            prop: 'paperCode',
            width: 150
          },
          {
            label: 'Publish Date',
            prop: 'achievementDate',
            width: 130
          },
          {
            label: 'Brief Introduction',
            prop: 'achievementBrief',
            tooltip: true
          }
        ],
        list: [
        ]
      },
      tabs: {
        current: 'description',
        list: [
          {
            key: 'description',
            label: 'Detailed description of data'
          },
          {
            key: 'extra',
            label: 'Extended information'
          },
          {
            key: 'results',
            label: 'Reference'
          },
          {
            key: 'related-literature',
            label: 'Related data'
          }
        ]
      }
    }
  },
  watch: {
    $route: 'fetchData'
  },
  mounted () {
    window.addEventListener('scroll', debounce(this.scrollPage))
    this.calcNavPosition()
    this.initMap()
    this.fetchData()
  },
  destroyed () {
    window.removeEventListener('scroll', debounce(this.scrollPage))
  },
  methods: {
    /**
     * 导航激活
     * @param index
     * @return {string}
     */
    activeNav (index) {
      return this.nav.current === index ? 'active' : ''
    },
    /**
     * tab激活
     * @param key
     * @return {string}
     */
    activeTab (key) {
      return this.tabs.current === key ? 'active' : ''
    },
    // 控制页面左侧导航位置
    calcNavPosition () {
      this.$nextTick(() => {
        const options = this.nav.options
        options.forEach((item, index) => {
          const navItem = document.querySelector(`#${item.anchor}`)
          if (navItem && navItem.offsetTop) {
            options[index].top = navItem.offsetTop
          }
        })
      })
    },
    // 跳转中文网站
    checkChinese () {
      // 跳转时加密
      if (this.chinese.id) {
        // 暂时不加密this.$Base64.encode()
        const url = `http://www.casodc.com/data/metadata-special-detail?id=${this.chinese.id}`
        window.open(url, '_blank')
      }
    },
    // 统计访问量
    countVisit () {
      const params = new FormData()
      params.append('moduleName', '专题数据产品')
      params.append('entryAddr', window.location)
      params.append('datasetId', this.id)

      this.axios.post(other.visit, params).then(() => {
      }).catch(err => {
        console.error(err)
      })
    },
    // 获取路由数据
    fetchData () {
      const query = this.query = this.$route.query
      if (query.id) {
        // 暂时不加密this.$Base64.decode()
        this.id = this.ftp.id = query.id
        this.ftp.url = ''
        this.countVisit()
        this.loadData()
      }
    },
    /**
     * 获取FTP文件大小
     * @param url FTP文件路径
     */
    getFtpFilesSize (url) {
      if (!url) {
        return null
      }
      const params = new FormData()
      params.append('url', url)
      this.axios.post(metadata.size, params).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.bar[1].value = res.data || '0MB'
        }
      }).catch(err => {
        this.$message.error('系统问题，请联系管理员！')
        console.error(err)
      })
    },
    handleCurrentChange (e) {
      this.pager.current = e
    },
    // 初始化地图
    initMap () {
      this.map = new maptalks.Map('thumbnail', {
        attribution: '',
        baseLayer: new maptalks.TileLayer(
          'vector',
          mapConfigs.getBase('vector')
        ),
        bearing: 0,
        center: [143.283294, 23.720231],
        zoom: 2,
        minZoom: 1
      })
      this.layer = new maptalks.VectorLayer('station').addTo(this.map)
    },
    // 初始化数据
    loadData () {
      const params = new FormData()
      params.append('Id', this.id)

      this.axios.post(metadata.info, params)
        .then((_http) => {
          const code = _http.data.code
          if (code === 200) {
            const data = _http.data.data
            // 中文ID
            this.chinese.id = data.cnMetadataId
            this.loadResultData()
            // doi访问地址
            data.doi = data.doi ? `http://dx.doi.org/${data.doi}` : ''
            // cstr访问地址
            data.cstr = data.cstr ? `https://datapid.cn/CSTR:${data.cstr}` : ''
            // ftp文件
            this.ftpDownloadUrl = data.ftpDownloadUrl.slice()
            this.ftp.url = data.ftpDownloadUrl
            window.url = data.ftpDownloadUrl
            // 电话显示时+86
            data.centerContactWay = data.centerContactWay ? `(86)${data.centerContactWay}` : '-'
            // 缓存数据
            Object.assign(this.buffer, data)
            // 共享方式
            this.shareMode = data.shareMode
            // 附件
            if (data.accessoriesList.length) {
              this.attachment = data.accessoriesList.map((item) => {
                const name = item.filePath.replace(/.*\//, '')
                const label = name.replace(/-\d+\.\w+/, '')
                return {
                  label,
                  name,
                  url: this.$baseURL + item.filePath
                }
              })
            }
            // 顶部数据带
            this.bar.forEach((item, index) => {
              const value = data[item.prop]
              if (item.datetime) {
                this.bar[index].value = (value || '').replace(/T/, ' ')
                  .replace(/(.*\s\d{2}:\d{2}).*/, '$1')
              } else {
                this.bar[index].value = value
              }
            })
            // 基础信息
            let latitude = null
            let longitude = null
            this.base.forEach((item, index) => {
              if (item.lon) {
                // 经度范围
                const lonArray = data.longitude.split('')
                if (lonArray.includes(['-', '°', '′', '″', null])) {
                  return null
                }
                if (data.longitude === '-') {
                  this.base[index].value = '-'
                  return null
                }
                const lon = data.longitude.split('~')
                if (lon[0] === 'null' || lon[0] === 'undefined') {
                  return null
                }
                lon[0] = lon[0] - 0
                let lonStart = ''
                if (lon[0] || lon[0] === 0) {
                  lonStart = lon[0] - 0 < 0 ? `${-lon[0]}°W` : `${lon[0]}°E`
                } else {
                  lonStart = '-'
                }
                if (lon.length === 1) {
                  this.base[index].value = lonStart
                  longitude = lon[0] // 构造定位经纬度
                } else {
                  if (lon[1] === 'null' || lon[1] === 'undefined') {
                    return null
                  }
                  lon[1] = lon[1] - 0
                  let lonEnd = ''
                  if (lon[1] || lon[1] === 0) {
                    lonEnd = lon[1] - 0 < 0 ? `${-lon[1]}°W` : `${lon[1]}°E`
                  } else {
                    lonEnd = '-'
                  }
                  this.base[index].value = `${lonStart}~${lonEnd}`
                  longitude = `${lon[0]}~${lon[1]}` // 构造定位经纬度
                }
              } else if (item.lat) {
                const latArray = data.latitude.split('')
                if (latArray.includes(['-', '°', '′', '″'])) {
                  return null
                }
                // 纬度
                if (data.latitude === '-') {
                  this.base[index].value = '-'
                  return null
                }
                const lat = data.latitude.split('~')
                if (lat[0] === 'null' || lat[0] === 'undefined') {
                  return null
                }
                lat[0] = lat[0] - 0
                let latStart = ''
                if (lat[0] || lat[0] === 0) {
                  latStart = lat[0] < 0 ? `${-lat[0]}°S` : `${lat[0]}°N`
                } else {
                  latStart = '-'
                }
                if (lat.length === 1) {
                  this.base[index].value = latStart
                  latitude = lat[0] // 构造定位经纬度
                } else {
                  if (lat[1] === 'null' || lat[1] === 'undefined') {
                    return null
                  }
                  lat[1] = lat[1] - 0
                  let latEnd = ''
                  if (lat[1] || lat[1] === 0) {
                    latEnd = lat[1] < 0 ? `${-lat[1]}°S` : `${lat[1]}°N`
                  } else {
                    latEnd = '-'
                  }
                  this.base[index].value = `${latStart}~${latEnd}`
                  latitude = `${lat[0]}~${lat[1]}` // 构造定位经纬度
                }
              } else if (item.time) {
                // 时间范围
                this.base[index].value = [
                  (data.dataBeginTime).replace(/^(\d{4})-(\d{2}).*/, '$1.$2'),
                  (data.dataEndTime).replace(/^(\d{4})-(\d{2}).*/, '$1.$2')
                ].join('-')
              } else {
                this.base[index].value = data[item.prop] || '-'
              }
            })
            // 数据卡
            this.card.forEach((item, index) => {
              this.card[index].value = data[item.prop] || '-'
            })
            // 插图轮播
            if (data.imagesList.length) {
              this.carousel = data.imagesList.map((item) => {
                return {
                  path: this.$baseURL + item.filePath
                }
              })
            }
            // 联系信息
            this.contact.forEach((item, index) => {
              this.contact[index].value = data[item.prop] || '-'
            })
            // 数据描述
            this.description.forEach((item, index) => {
              this.description[index].value = data[item.prop] || '-'
            })
            // 贡献者分为名，姓
            const name = data.dataProducer.split(' ')
            if (name && name.length > 1) {
              this.detail.firstName = name[0]
              this.detail.lastName = name[1]
            }
            // 贡献者 & 名称 & 摘要
            this.detail.contributor = data.dataProducer || ''
            this.detail.name = data.enName
            this.detail.summary = data.dataBrief
            // 扩展信息
            this.extra.forEach((item, index) => {
              this.extra[index].value = data[item.prop] || '-'
            })
            // 申明
            this.statement.forEach((item, index) => {
              this.statement[index].value = (data[item.prop] || '-')
                .replace(/(\s{3,})/g, '@@')
                .split('@@')
            })
            // 引用说明
            if (data.pertinentLiterature) {
              this.literature = data.pertinentLiterature
                .replace(/(\s{2,})/g, '@@')
                .split('@@')
            }
            // 导航位置更新
            this.calcNavPosition()
            // FTP文件大小
            this.getFtpFilesSize(this.ftp.url)
            // 空间位置
            if (longitude && latitude) {
              this.updateMap(longitude, latitude)
            }
          }
        })
    },
    // 获取相关成果
    loadResultData () {
      const params = new FormData()
      const ids = [this.chinese.id, this.id].join(',')
      params.append('usedDataset', ids)
      params.append('offset', this.pager.current)
      params.append('limit', this.pager.size)
      this.axios.post(result.pageSys, params).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.results.list = res.data.records.map(item => {
            item.achievementDate = item.achievementDate.replace(/T/, ' ').replace(/\..*/, '')
            return item
          })
          this.pager.total = res.data.total
        }
      }).catch(err => {
        console.error(err)
      })
    },
    // 关闭相关成果弹窗
    resetPreview () {
      this.drawer.show = false
    },
    scrollPage () {
      if (this.nav.disabled) {
        return null
      }

      const offsetY = window.scrollY - 200
      const options = this.nav.options
      const limit = options.length
      const tab = this.tabs.current

      for (let i = 1; i < limit; i++) {
        const item = options[i]
        const diff = item.diff || 0
        const last = options[i - diff - 1]
        // 确定滚动高度
        const row = item.top > offsetY && last.top < offsetY
        // 确定 tabs 激活项
        const column = item.tab ? item.tab === tab : true
        if (row && column) {
          this.nav.current = i
          this.nav.visible = i + 1 < limit
          return null
        }
      }

      // 无匹配的选项，初始化导航器
      this.nav.current = -1
      this.nav.visible = false
    },
    showMore () {
      this.dialog.visible = true
    },
    toggleFile (index, chekced) {
      this.download.list[index].chekced = !chekced
    },
    toggleFileAll (checked) {
      this.download.checkeds = []

      if (checked) {
        this.download.checkeds = this.download.list.map((item) => item.name)
      }
    },
    toggleFtp () {
      if (this.shareMode !== 'private' && this.shareMode !== '暂不共享') {
        return true
      } else if (this.query.code) {
        const code = decodeURI(atob(this.query.code))
        return code === this.query.id
      } else {
        return false
      }
    },
    toggleFtpShare () {
      if (this.shareMode === 'open' || this.shareMode === '完全共享') {
        return true
      } else if (this.shareMode === 'applicable' || this.shareMode === '申请共享') {
        // 有个很严重的问题，我记得之前改过了~现在正式网站上，英文页面的申请共享的数据怎么也可以直接下载了啊？之前不是改成不能直接下载了吗？改一下吧，现在网站上有一些MG数据，我看也都直接下了
        // 2024年8月16日 09点20分修改 门户网站设计群-李一凡提出
        return false
      } else if (this.query.code) {
        const code = decodeURI(atob(this.query.code))
        return code === this.query.id
      } else {
        return false
      }
    },
    toggleNav (index) {
      const data = this.nav.options[index]
      if (index) {
        this.nav.current = index
      } else {
        this.nav.current = -1
      }
      const anchor = document.querySelector(`#${data.anchor}`)
      if (anchor) {
        this.nav.disabled = true
        setTimeout(() => {
          this.nav.disabled = false
        }, 1000)

        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
      if (data.tab) {
        this.toggleTab(data.tab)
      }
    },
    /**
     * 相关成果查看
     * @param e
     */
    toggleResultDrawer (e) {
      this.drawer.id = e.id
      this.drawer.show = true
    },
    /**
     * tab切换
     * @param key
     */
    toggleTab (key) {
      this.tabs.current = key
      this.scrollPage()
    },
    /**
     * 地图更新-点位叠加/区域叠加
     * @param longitude
     * @param latitude
     * @return {null}
     */
    updateMap (longitude, latitude) {
      if (this.geometry) {
        this.geometry.remove()
        this.geometry = null
      }

      const drawMarker = (lon, lat) => {
        // 点位
        lon -= 0
        lat -= 0
        this.geometry = new maptalks.Marker(
          [lon, lat],
          {
            symbol: {
              markerType: 'pin',
              markerFill: 'rgb(135,196,240)',
              markerFillOpacity: 0,
              markerLineColor: '#34495e',
              markerLineWidth: 2,
              markerLineOpacity: 1,
              markerLineDasharray: [],
              markerWidth: 40,
              markerHeight: 40,
              markerDx: 0,
              markerDy: 0,
              markerOpacity: 1
            }
          }
        ).addTo(this.layer)
        this.map.setCenter(new maptalks.Coordinate(lon, lat))
      }

      // const numRE = /^\d+(\.\d+)*$/
      const numsRE = /~/
      if (numsRE.test(latitude) && numsRE.test(longitude)) {
        // 区域
        const lat = latitude.split('~').map((item) => item - 0)
        const lon = longitude.split('~').map((item) => item - 0)
        const xmin = lon[0] < 0 ? -lon[0] + 180 : lon[0]
        const xmax = lon[1]
        const ymin = lat[0]
        const ymax = lat[1]
        if (xmin === xmax && ymin === ymax) {
          drawMarker(xmin, ymin)
          return null
        }
        this.geometry = new maptalks.Polygon(
          [
            [xmax, ymax],
            [xmin, ymax],
            [xmin, ymin],
            [xmax, ymin],
            [xmax, ymax]
          ],
          {
            symbol: {
              polygonFill: 'rgb(135,196,240)',
              polygonOpacity: 0,
              lineColor: '#1bbc9b',
              lineWidth: 2,
              lineJoin: 'round',
              lineCap: 'round',
              lineDasharray: null,
              lineOpacity: 1
            }
          }
        ).addTo(this.layer)
        this.map.fitExtent(new maptalks.Extent(
          xmin, ymin, xmax, ymax
        ), 0)
      } else {
        drawMarker(longitude, latitude)
      }
    }
  }
}
</script>

<style scoped>
/* 0. 容器 */
.app-metadata-detail {
  background-color: transparent;
  margin-top: 50px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.to-chinese {
  font-size: 18px;
  font-weight: bold;
  left: 50%;
  position: absolute;
  top: 2px;
  transform: translateX(516px);
}

/* 1. 正文 */
.metadata-content {
  background-color: white;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 40px;
  min-height: calc(100vh - 200px - 164px);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 233px;
  padding-left: 110px;
  padding-right: 110px;
  padding-top: 40px;
  width: 1200px;
}
/* 1.0. 通用内容 */
.metadata-section-header {
  height: 20px;
  line-height: 20px;
  text-align: left;
}
.metadata-section-cude {
  color: black;
  display: inline-block;
  font-size: 6px;
  height: 20px;
  margin-right: 3px;
  transform: translateY(-2px);
}
.metadata-section-icon {
  border-left-color: rgba(16, 90, 190, 1);
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: rgba(16, 90, 190, 1);
  border-right-style: solid;
  border-right-width: 8px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  width: 12px;
}
.metadata-subtitle {
  color: rgba(16, 90, 190, 1);
  font-size: 18px;
}
/* 1.1. 标题 */
#metadata-title {
  color: rgba(16, 90, 190, 1);
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
#metadata-title span {
  border-bottom: 2px solid rgba(16, 90, 190, 1);
  padding-bottom: 2px;
}
/* 1.2. 数据贡献者 */
#metadata-contributor {
  color: rgba(169, 169, 169, 1);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
#metadata-contributor .label {
  font-weight: 600;
}
/* 1.3. 数据信息条 */
#metadata-detail-bar {
  font-size: 14px;
  margin-bottom: 13px;
  margin-top: 13px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
}
#metadata-detail-bar li {
  color: #000;
  display: inline-block;
  list-style: none;
  padding-left: 65px;
  position: relative;
  width: 25%;
}
#metadata-detail-bar li:first-child {
  border-left: none;
}
#metadata-detail-bar .icon {
  border-radius: 50%;
  color: white;
  font-size: 26px;
  height: 40px;
  left: 5px;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 40px;
}
#metadata-detail-bar p {
  line-height: 25px;
}
/* 1.4. 数据信息卡 */
#metadata-detail-card {
  background-color: rgba(247, 247, 247, 1);
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}
#metadata-detail-card li {
  align-items: center;
  border-bottom-color: rgba(221, 221, 221, 1);
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-bottom: 5px;
  padding-top: 5px;
}
#metadata-detail-card .label {
  flex-shrink: 0;
  text-align: left;
  width: 120px;
}
/* 1.5. 基本信息 & 数据联系信息 */
#metadata-detail-base,
#metadata-detail-contact {
  margin-top: 50px;
  min-height: 170px;
  position: relative;
}
/* 基本信息 & 数据联系信息 */
.metadata-detail-contact-info,
.metadata-detail-base-info {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.metadata-detail-base-info ul {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 12px;
  padding-left: 20px;
  width: 665px;
}
.metadata-detail-base-info ul li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
  vertical-align: top;
}
.metadata-detail-base-info ul li .label {
  color: #000;
  flex-shrink: 0;
  font-weight: 600;
  width: 130px;
}
/* 数据联系信息 */
.metadata-detail-contact-info ul {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 12px;
  padding-left: 20px;
}
.metadata-detail-contact-info ul li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
  width: 100%;
}
.metadata-detail-contact-info ul li .label {
  flex-shrink: 0;
  font-weight: 600;
  width: 125px;
}
/* 1.6. 数据摘要 */
#metadata-summary {
  margin-top: 20px;
}
.metadata-summary-content {
  margin-top: 10px;
  padding-left: 20px;
}
.metadata-controls {
  margin-top: 15px;
}
/* 1.7 Tabs */
#metadata-tabs {
  margin-bottom: 10px;
  margin-top: 60px;
}
.tabs-header {
  display: flex;
  padding-left: 0;
  text-align: center;
}
.tabs-header li {
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  cursor: pointer;
  flex-grow: 1;
  list-style: none;
  padding-left: 20px;
  padding-right: 20px;
}
.tabs-header li.active {
  border-bottom-color: dodgerblue;
  color: dodgerblue;
  cursor: text;
}

.result-pager {
  margin-top: 10px;
  text-align: center;
}

/* 1.7.1.  数据详细描述 */
#metadata-description {
  margin-top: 20px;
}
#metadata-description ul {
  padding-left: 0;
}
#metadata-description li {
  list-style: none;
  margin-top: 10px;
}
#metadata-description .subtitle {
  background-color: ghostwhite;
  border-left-color: blue;
  border-left-style: solid;
  border-left-width: 3px;
  padding-left: 5px;
}
#metadata-description p {
  text-indent: 4ch;
  margin-top: 5px;
}
/* 1.7.2. 数据使用申明 */
#metadata-usage-statement {
  margin-top: 20px;
}
#metadata-usage-statement ul {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
#metadata-usage-statement li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
#metadata-usage-statement .subtitle {
  flex-shrink: 0;
  text-align: left;
  width: 135px;
}
#metadata-usage-statement p {
  flex-grow: 1;
}
#metadata-usage-statement.metadata-usage-statement .subtitle {
  flex-shrink: 0;
  width: 220px;
}
/* 1.7.3. 数据附件 */
#metadata-attachment {
  margin-top: 20px;
}
#metadata-attachment-list {
  margin-top: 10px;
  padding-left: 20px;
}
#metadata-attachment-list li {
  list-style: none;
  margin-top: 5px;
}
#metadata-attachment-list p {
  padding-left: 20px;
}
#metadata-attachment-list a {
  float: right;
}
/* 1.7.4. 相关文献 */
#metadata-related-literature {
  margin-top: 20px;
}
#metadata-related-literature .empty {
  color: red;
  margin-bottom: 20px;
  margin-top: 20px;
  text-indent: 4ch;
}
/* 1.8. 数据下载 */
#dataset-download {
  margin-top: 50px;
}
.download-toolbar {
  margin-bottom: 5px;
  margin-top: 5px;
  position: relative;
}
.download-path {
  display: inline;
}
.download-path li {
  color: dodgerblue;
  cursor: pointer;
  display: inline;
  line-height: 28px;
  list-style: none;
  margin-right: 20px;
  padding-left: 0;
  position: relative;
}
.download-path li::after {
  color: darkgray;
  content: '>';
  position: absolute;
  right: -10px;
}
.download-path li:last-child {
  color: darkgray;
  cursor: text;
  margin-right: 0;
}
.download-path li:last-child::after {
  content: '';
  right: 0;
}
.download-controls {
  float: right;
}
.download-list .name {
  display: inline-block;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  width: 630px;
}
.download-list .base {
  overflow: hidden;
  text-overflow: ellipsis;
}
.download-list .popup {
  background-color: white;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  display: block;
  opacity: 0;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  position: absolute;
  top: 25px;
  visibility: hidden;
  right: 0;
  z-index: 9;
}
.download-list .name:hover .popup {
  opacity: 1;
  transition-delay: 1s;
  transition-duration: .3s;
  visibility: visible;
}
.download-list .icon {
  height: 18px;
  transform: translateY(4px);
  width: 18px;
}
.download-list .size {
  display: inline-block;
  text-align: center;
  width: 75px;
}
.download-list .type {
  display: inline-block;
  text-align: center;
  width: 52px;
}
.download-list .time {
  display: inline-block;
  text-align: center;
  width: 125px;
}
.download-folder {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
  padding-left: 24px;
  width: 100%;
}
.download-folder .name {
  color: dodgerblue;
  cursor: pointer;
}
.download-more {
  border-top-color: ghostwhite;
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;
  line-height: 28px;
  margin-top: 10px;
  text-align: center;
}
/* 1.9. 平台申明 */
#platform-statement {
  margin-top: 20px;
}
#platform-statement ul {
  background-color: rgba(250, 236, 227, 0.3);
  border-color: rgba(254, 235, 225, 1);
  border-style: solid;
  border-width: 1px;
  color: rgba(255, 72, 29, 1);
  margin-top: 20px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
}
#platform-statement li {
  list-style: '*';
  margin-top: 10px;
  padding-left: 10px;
}
/* 1.10 相关成果 */
#related-results {
  margin-bottom: 10px;
  margin-top: 20px;
}
#related-results h3 {
  margin-bottom: 10px;
}

/* 2.1. 轮播图 */
.metadata-carousel {
  background-color: aliceblue;
  flex-shrink: 0;
  height: 143px;
  position: relative;
  width: 231px;
}
.click-more {
  position: absolute;
  right: 45px;
  top: 163px;
}
.metadata-carousel-dialog {
  background-color: aliceblue;
  height: 500.5px;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  width: 808.5px;
}
/* 2.2. 缩略图 */
.metadata-thumbnail {
  background-color: aliceblue;
  flex-shrink: 0;
  height: 170px;
  width: 240px;
}

/* 页面导航 */
.page-nav {
  left: calc(50vw - 820px);
  padding-right: 65px;
  position: fixed;
  text-align: right;
  top: 400px;
  width: 235px;
}
.page-nav::after {
  background-color: rgba(234, 234, 234, 1);
  content: ' ';
  height: calc(100% - 30px);
  position: absolute;
  right: 49px;
  top: 15px;
  width: 2px;
  z-index: 0;
}
.page-nav-item {
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  list-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
}
.page-nav-item.active span {
  background-color: rgba(64, 158, 255, 1);
  color: white;
  display: inline-block;
  line-height: 20px;
  padding-left: 8px;
  position: relative;
}
.page-nav-item.active span::after {
  border-bottom-color: transparent;
  border-left-color: rgba(64, 158, 255, 1);
  border-right-color: transparent;
  border-top-color: transparent;
  border-style: solid;
  border-width: 10px;
  box-sizing: border-box;
  content: ' ';
  height: 20px;
  position: absolute;
  right: -20px;
  top: 0;
  width: 10px;
}
.page-nav-item::after {
  background-color: rgba(209, 209, 209, 1);
  border-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  content: ' ';
  height: 10px;
  position: absolute;
  right: -20px;
  top: 5px;
  width: 10px;
  z-index: 1;
}
.page-nav-item:first-child:after,
.page-nav-item:last-child:after {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(209, 209, 209, 1);
}
.page-nav-item.active::after {
  background-color: rgba(64, 158, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}
</style>
